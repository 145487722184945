<template>
<div class="t-dialog t-mask" v-if="visible" @click="clickMask">
  <div class="t-dialog-show" @click.stop>
    <div class="t-dialog-msg" v-html="message"></div>
    <div class="t-dialog-buttons">
      <van-button type="primary" @click="$emit('confirm')"  v-html="confirmText"></van-button>
      <van-button @click="$emit('cancel')" type="primary" plain v-html="cancelText" style="margin-left: 50rem;" v-if="type==='confirm'"></van-button>
    </div>
  </div>
</div>
</template>

<script>

export default {
  name:"tDialog",
  emits:["confirm","cancel","mask","update:visible"],
  props:{
    message:String,
    confirmText: {
      type:String,
      default:"确定"
    },
    cancelText: {
      type:String,
      default:"取消"
    },
    type:{
      type:String,
      default:"confirm",
      validator(value){
        return ["confirm","alert"].includes(value)
      }
    },
    visible:Boolean,
    clickMaskClose:Boolean
  },
  methods:{
    clickMask(){
      if (this.clickMaskClose){
        this.$emit("update:visible",false);
        this.$emit("mask");
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.t-dialog{
  display: flex;
  justify-content: center;
  align-items: center;
  .t-dialog-show{
    width: calc(100% - 32rem - 84rem);
    border-radius: 6rem;
    background-color: white;
    padding:40rem 42rem 30rem;
    font-size: 16rem;
    text-align: center;
    .t-dialog-msg{
      margin-bottom: 30rem;
      color: #333;
    }
    .t-dialog-buttons{
      text-align: center;
    }
  }
}
</style>
