<template>
<div>

</div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import {Toast} from "vant";
import {checkNetwork,getWxCode,getOpenId} from "@/utils/api.js";

let route = useRoute();
let router = useRouter();
let store = useStore();
let goods_pack_code = route.query.goods_pack_code;
let baseUrl = import.meta.env.API_WX_URL;
let code = route.query.code;


if (store.getters.microMessenger.flag){

  // 如果没有code 则获取code
  if (!code){
    store.commit("setMicroMessenger", {goods_pack_code});
    getWxCode();
  }else{ // 有code 则获取openid
    getOpenId(code).then((res)=>{
      if (res.success) {
        if (store.getters.microMessenger.goods_pack_code)
          checkNetwork();
        else router.replace("/login");
      }
      else {
        Toast({
          message:res.msg,
        })
      }
    })

  }
  // router.replace("/login");

}else{
  // 不是微信浏览器
  // 通过扫码进入
  if (goods_pack_code != null){
    router.replace({
      path:"/error",
      query:{
        type:4,
        width:139,
        height:139
      }
    })
  }
}
</script>

<style scoped>

</style>
