<template>
  <div class="t-input">
    <div class="label">{{ label }}</div>
    <div class="input">
      <t-select v-if="select"
                :placeholder="placeholder"
                :readonly="readonly"
                :model-value="modelValue"
                @update:model-value="$emit('update:modelValue',$event)"
                :options="options"
                :disabled="disabled"
                :title="props&&props.title || ''"
                @change="$emit('change',$event)"
                @onClick="$emit('click',$event)"
                :picker-props="props"></t-select>
      <t-cascader v-else-if="cascader"
                :placeholder="placeholder"
                :readonly="readonly"
                :model-value="modelValue"
                @update:model-value="$emit('update:modelValue',$event)"
                :options="options"
                :title="props&&props.title || ''"
                @change="$emit('change',$event)"
                @onClick="$emit('click',$event)"
                :cascader-props="props"></t-cascader>
      <van-field
          :model-value="modelValue"
          :readonly="readonly"
          @update:model-value="$emit('update:modelValue',$event)"
          v-else-if="input"
          :placeholder="placeholder"
          @change="$emit('change',$event)"
          :maxlength="maxlength"
          v-bind="props"></van-field>
    </div>
  </div>
</template>

<script>
import TSelect from "../Select/index.vue"
import TCascader from "../Cascader/index.vue"
export default {
  name: "TInput",
  components: {TSelect,TCascader},

  emits:["update:modelValue","change",'click'],
  props:{
    modelValue:[String,Object,Number],
    // 可传组件原生props
    props:Object,
    readonly:Boolean,
    disabled:Boolean,
    placeholder:String,
    label:String,
    // 当为select模式的时候 , 传入options值
    options:Object,
    // 以下参数代表使用的模式, 唯一值
    select:Boolean,
    maxlength:[String,Number],
    cascader:Boolean,
    input:{
      type:Boolean,
      default:true
    }
  },
  computed:{
    val:{
      get(){
        return this.modelValue;
      },
      set:(value)=>{
        this.$emit("update:modelValue",value);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.t-input{
  .label{
    font-size: 14rem;
    color: rgba(0,0,0,.6);
    font-weight: 400;
    margin-bottom: 10rem;
    text-indent: 16rem;
    letter-spacing:2rem;
  }
}
</style>
