<template>
  <div class='t-verify'>
    <div class='t-logo' v-if="logo">
      <t-img class='img' :src='logo' alt='' />
    </div>
    <div class='content'>
      <div class='title' v-html='title' v-if="title"></div>
      <div class='phone'>
        <van-field class='input' type='number' v-model.number='d_phone' maxlength="11" label-width="30rem" placeholder='输入您当前使用的手机号' label='+86'>
        </van-field>
      </div>
      <div class='verify'>
        <van-field placeholder='输入验证码' type='number' :maxlength="4" v-model='d_verify' class='input'>
          <template #button>
            <span style='font-size: 14rem;color: black;' :style="{color:isCD?'#ccc':'#6E7380'}" @click='getVCode'>{{ isCD ? "再次获取(" + (cd.seconds + 1) + ")" : "获取验证码" }}</span>
          </template>
        </van-field>
      </div>
      <slot name="protocol"></slot>
      <t-button block type='primary' :disabled='_disabled' @click="$emit('onSubmit', $event)">{{ buttonText }}</t-button>
    </div>
  </div>
  <van-overlay :show="overlay" style="display: flex;justify-content: center;align-items: center">
    <van-loading size="24px" text-color="#0094ff" color="#0094ff">加载中...</van-loading>
  </van-overlay>
</template>

<script>
import {useCountDown} from "@vant/use";
import {ref} from "vue";
import {Toast} from "vant";
import axios from "../../axios/index";
import {useRoute} from "vue-router";

export default {
  name: "t-verify-code",
  emits: ['update:phone', 'update:verify', "onSubmit"],

  setup(props) {
    const route = useRoute();
    let isCD = ref(false);
    const countDown = useCountDown({
      time: 30 * 1000,
      onFinish() {
        isCD.value = false;
      }
    })
    const getVCode = () => {
      if (!props.phone) {
        Toast('请输入手机号');
        return;
      }
      if (props.phone) {
        let reg = /^((12[0-9])|(13[0-9])|(14[0-9])|(15[0-9])|(16[0-9])|(17[0-9])|(18[0-9])|(19[0-9]))\d{8}$/;
        if (!reg.test(props.phone)) {
          Toast('请输入正确手机号');
          return false;
        }
      }
      if (!isCD.value) {
        overlay.value = true
        let st = 2;
        if (props.submitType != null)st = props.submitType;
        else if(route.query.type) st = route.query.type;
        axios.post("api/recruit/auth/mobile", {submit_type: st, mobile: props.phone, module: 11}, {fullData: true}).then(result => {
          if (result.data.status === 0) {
            Toast(result.data.msg)
            countDown.reset();
            countDown.start();
            isCD.value = true;
            overlay.value = false
          } else {
            overlay.value = false
            Toast(result.data.msg)
          }
        }).catch(()=>overlay.value = false)
      }
    }

    const overlay = ref(false)

    return {
      getVCode,
      cd: countDown.current,
      isCD,
      overlay
    }
  },
  computed: {
    _disabled() {
      if (this.disabled!=null)return this.disabled;
      return !this.d_phone || !this.d_verify;
    },
    d_phone: {
      get() {
        return this.phone
      },
      set(value) {
        this.$emit("update:phone", value)
      }
    },
    d_verify: {
      get() {
        return this.verify
      },
      set(value) {
        this.$emit("update:verify", value)
      }
    },
  },
  props: {
    phone: [String, Number],
    verify: [String, Number],
    logo: String,
    title: String,
    buttonText: String,
    disabled: {
      type:Boolean,
      default:null
    },
    submitType:{
      type:[Number,String]
    }
  }
}
</script>

<style lang='scss' scoped>
.t-verify {
  padding: 40rem;

  .t-logo {
    padding-top: 10rem;
    padding-bottom: 56rem;
    text-align: center;

    .img {
      width: 99rem;
      height: 105rem;
    }
  }

  .title {
    padding-bottom: 30rem;
    color: black;
    font-size: 18rem;
    font-weight: 500;
  }

  .phone {
    :deep(.input.van-field .van-field__value) {
      padding-left: 10rem;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  .verify {
    margin-bottom: 36rem;
  }

  .input {
    margin-bottom: 19rem;
  }
}
</style>
