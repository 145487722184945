<!-- 订单列表 -->
<template>
  <div class="orders">
    <t-result v-if="hasOrder === 0"
      hint="暂无已报名的服务"
      img="order-01.png"
      :imgProps="{
      width:'210rem',
      height:'180rem'
    }"
    >
    </t-result>
    <div class="order" v-else-if="hasOrder === 1">
      <div class="top-bg">
        <div class="bar">
          <div class="line"></div>
        </div>
      </div>
      <div class="content">
        <t-order style="margin-bottom: 10rem;" v-for="item in data" :data="item.list" @click="orderClick(item.status,item.num,item.offline)">
          <template #header>
            <div class="header">
              <div class="number">
                <span style="margin-right: 10rem;">订单编号</span>
                <span style="font-weight: 400">{{ item.num }}</span>
              </div>
              <div >
                <t-icon v-if="item.status === 4" size="45rem" color="#1DC060" name="finished"></t-icon>
                <img v-else-if="[5, 6].includes(item.status)" class="z-refund-img" src="@/assets/imgs/zp-00.png" alt="已退款" />
                <span  v-else-if="item.status === 2" class="span-width" style="color: rgba(0, 0, 0, 0.5);">已取消</span>
                <span  v-else-if="item.status === 1" class="span-width" style="color: #F74156;">待支付</span>
                <span  v-else-if="item.status === 3" class="span-width" style="color: #FF6E21;">已支付</span>
              </div>
            </div>
          </template>
          <template #footer v-if="![2,4].includes(item.status)">
            <div v-if="item.status === 1" style="padding-bottom: 14rem;text-align: right;" @click.stop>
              <t-button class="z-order-btn" style="border: none;color: #fff" @click="cancelOrder(item.num)" size="middle" plain type="primary" :round="false" :block="false">
                取消订单
              </t-button>
              <t-button v-if="item.offline" class="z-order-btn" style="background-color: #F8BF5D !important;border-color: #edb960 !important;" @click="handlePath(item.num)" size="middle" type="warning" :round="false"  :block="false">查看详情</t-button>
              <t-button v-else class="z-order-btn" @click="orderClick(item.status,item.num,item.offline)" size="middle" type="primary" :round="false"  :block="false">
                去支付
              </t-button>
            </div>
          </template>
        </t-order>
      </div>
    </div>

<!--    <div class="order-btns" v-if="hasOrder ===1">-->
<!--&lt;!&ndash;      <div style="height: 40rem"></div>&ndash;&gt;-->
<!--    </div>-->
    <div ref="bottom" :class="['order-btns',hasOrder!==0?'order-btns-position':'']" v-if="hasOrder !== -1">
<!--      <t-button style="border:1px solid #417FF7;background-color: transparent;color:#417FF7;">报名服务</t-button>-->
      <div @click="onRegister">
        <img src="../../assets/imgs/dynamic-button.gif" alt="" style="width: 100%;height: 4.8em">
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref,nextTick} from "vue";
import axios from "../../axios/index.js";
import {onHistoryBack} from "../../utils/recordRouter.js";
import {useRouter} from "vue-router";
import {showLoading, clearCache, setPathInfo, getCurPathInfo, setCurPathInfo} from "../../utils/utils.js";
import constants from "../../utils/constants.js"
import tools from "../../utils/tools.js";
import {Dialog, Toast} from "vant";
const router = useRouter();
const hasOrder = ref(-1);
const data = ref([{
  num: null,
  list: {},
  status: null
}])
const bottom = ref(null);
const onHistory = onHistoryBack((self)=>{
  console.log("禁止返回上一页")
},false);
let curPath = getCurPathInfo();
setCurPathInfo({
  ...curPath,
  order:"/order"
})
let signContract = tools.debounce(order_number=>{
  showLoading(true);
  axios.post("/api/recruit/family/contract",{order_number}).then(res=>{
    /*Dialog.alert({
      message:'请注意接收通知短信，点短信内的链接可继续报名。',
      confirmButtonText:"我知道了"
    })*/
    showLoading(false);
    /*router.push({path:'/webview',query: {
        url:res.url,
        title:"报名"
      }})*/
    window.location.href=res.url
  }).catch(res=>showLoading(false))
},false)
function getData() {
  showLoading(true);
  axios.get("/api/recruit/family/list").then(res=>{
    showLoading(false)
    if (res.length > 0){
      hasOrder.value = 1;
      data.value = res.map(item => {
        // 状态 1 待支付 2 已取消 3 待报名 4 已完成  5 待报名退款  6 已完成退款
        // 支付方式 offline 0 非线下  1线下
        // 审批状态 offline_status  1 待审批 2 已通过 3 已驳回
        const {status} = item
        let li = {};
        if ([1, 2, 3, 5].includes(status)) {
          li = {
            "报名学校": item.school_name,
            "服务名称": item.service_name,
            "客户姓名": item.customer_name
          };
          if (status === 2) {
            li['取消订单时间'] = item.cancel_time
          }
          if (status === 3) {
            li['实付金额'] = `￥${(item.money || 0) / 100}`
            li['支付方式'] = item.payment_method
            li['支付成功时间'] = item.pay_time
          }
          if (status === 5) {
            li['学生姓名'] = item.student_name
            li['学生性别'] = item.sex
            li['咨询老师'] = item.teacher_name
            li['实付金额'] = `￥${(item.money || 0) / 100}`
            li['支付方式'] = item.payment_method
            li['支付成功时间'] = item.pay_time
          }
        }
        if ([4, 6].includes(status)) {
          li = {
            "报名学校": item.school_name,
            "报名学校顺序号": item.school_sn,
            // "协议编号": item.agreement_sn,
            "服务名称": item.service_name,
            "客户姓名": item.customer_name,
            "报名手机号": item.phone,
            "学生姓名": item.student_name,
            "学生性别": item.sex,
            "咨询老师": item.teacher_name
          };
          /* if(status === 4) {
            li['报名时间'] = item.sign_time
          }*/
          if (status === 6) {
            li['实付金额'] = `￥${(item.money || 0) / 100}`
            li['支付方式'] = item.payment_method
          }
        }
        return {
          source: item,
          num: item.order_number,
          money: item.money,
          status: item.status,
          offline: item.offline,
          list: li
        }
      })
      nextTick(()=>{
        document.body.append(bottom.value);
      })
    }else hasOrder.value = 0;
  }).catch(err=>showLoading(false))
}
getData();
function cancelOrder(val){
  Dialog.confirm({
    message:"确定取消订单?",
    confirmButtonColor:"#417FF7",
    cancelButtonText:"点错了",
  }).then(res=>{
    showLoading(true)
    axios.get("/api/recruit/family/cancel",{
      params:{
        order_number:val
      }
    }).then(res=>{
      showLoading(false);
      Toast({message:"订单已取消"});
      // router.back();
      router.replace({path:"/order-status",query:{
          status:2
        }})
    }).catch(res=>showLoading(false))
  })

}

function handlePath(num) {
  router.push(`/pay-info?order_number=${num}`);
}

function orderClick(status, num, offline){
  /*if(status === 4){
    router.push('/order-info?id=' + num)
  }else */
  if(status === 1){
    // 线下支付
    if(offline) {
      return handlePath(num)
    }
    showLoading(true);
    axios.post("/api/recruit/family/list-state",{
      order_number:num
    }).then(res=>{
      let status = res.status;
      switch (status) {
        case 1: // 待支付
          router.push('/wait-pay?order_number=' + num);
          break;
        case 2: // 待签约
          // router.push('/order-status?status=3');
          break;
        case 3: // 报名成功
          // router.push('/order-info?id=' + num);
          break;
      }
      showLoading(false)
    }).catch(err=>showLoading(false))

  }else if(status === 3){
    // router.push('/order-status?status=3')
  }
}
// 报名按钮
function onRegister() {
  clearCache([constants.baseConfig,constants.userBaseInfo,constants.registerCache]);
  router.push('/register');
}

</script>
<script>
export default {
  name:"register",
  beforeRouteLeave(to,from,next){
    let btns = document.querySelector(".order-btns.order-btns-position");
    btns?btns.remove():null;
    if (to.name.indexOf("register")>-1){
        // 清除预报名页面缓存
        to.name = 'register'+ (new Date()).getTime();
      }
      next();
  },
}
</script>
<style lang="scss" scoped>
.span-width{
  display: inline-block;
  font-size: 12rem;
  min-width: 40rem;
  font-weight: bold;
}
.orders{
  background-color: #F8F8F8;
  min-height: 100vh;
}
.order{
  margin-bottom: calc(30rem + 4.4em);
.top-bg{
  background-color: #417FF7;
  height: 80rem;
  display: flex;
  justify-content: center;
  padding-top: 28rem;
  .bar{
    width: 356rem;
    height: 30rem;
    background-color: #105DF2;
    border-radius: 15rem;
    .line{
      margin: 10rem auto 0;
      width: 342rem;
      border-radius: 7rem;
      height: 14rem;
      background-color: rgba(116, 163, 255, 0.4);

    }
  }
}
  .content{
    padding: 0 14rem 1rem;
    margin-top: -60rem;
    position: relative;
    z-index: 2;
    .header{
      padding-top: 20rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .number{
        font-weight: 600;
        span{
          font-size: 12rem;
        }
      }
      .z-refund-img {
        width: 34rem;
        height: 34rem;
      }
    }
  }
}
.order-btns{
  padding:10rem 40rem 10rem;
  width: calc(100% - 80rem);
  z-index: 10;
  background-color: #F8F8F8;
}
.z-order-btn {
  width: 100rem;
  height:40rem;
  font-size: 14rem;
  margin-left: 8rem;
  border-radius: 4rem;
}
</style>
