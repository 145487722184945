<template>
  <div class="no-network">
    <div class="t-empty">
      <img :src="img" alt="提示图" >
      <pre class="t-empty-text">网络连接失败<br/>请确保您的设备已连接到内网后重试</pre>

      <van-button type="primary" round block style="margin-top: 40rem;" v-show="refresh" @click="onRefresh">刷新</van-button>
    </div>
  </div>
</template>

<script setup>
import img from "@/assets/imgs/no-network.png"
import {useRoute} from "vue-router";
import router from "@/router/index.js";
import {checkNetwork} from "@/utils/api.js";

const route = useRoute();
const refresh = route.query.refresh;
function onRefresh(){
  checkNetwork(route);
}
</script>


<style scoped lang="scss">
.no-network{
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: #F7F7F7;
  height:100vh;
  .t-empty{
    background-color: #F7F7F7;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    &-text{
      font-size: 15rem;
      color: #3D3D3D;
      line-height: 24rem;
      text-align: center;
      margin-top: 30rem;
    }
  }
}
</style>