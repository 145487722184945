import axios from 'axios'
import tools from '../utils/tools.js'
import constants from '../utils/constants.js'
import router from '../router/index.js'
import { Toast } from 'vant'
import store from '../store/index.js'
import {getLoginPath, getLoginType} from "../utils/utils.js";

export default function configAxios(options = {}) {
  let _options = {
    baseURL: import.meta.env.API_BASE_URL,
    timeout: 10000,
    notify: true, // 错误提示
    fullData: false // 返回完整数据
  }
  _options = Object.assign({}, _options, options)

  let $axios = axios.create(_options)
  $axios.interceptors.request.use(config => {
    let token = tools.getSession(constants.token)
    let traceId = tools.getSession(constants.traceId)
    if (token) {
      config.headers.authorization = 'Bearer ' + token
      if (!!traceId) config.headers.traceid = traceId
    }
    return config
  })
  $axios.interceptors.response.use(
    res => {
      const config = res.config
      // 添加traceId
      if (res.data.traceId) tools.setSession(constants.traceId, res.data.traceId)
      if (res.data.status == null) {
        if (config.fullData) return Promise.resolve(res)
        else return Promise.resolve(res.data)
      }
      if (res.data.status === 0) {
        if (config.fullData) return Promise.resolve(res)
        else return Promise.resolve(res.data.data)
      } else if ([406, 100].includes(res.data.status)) {
        let loginType = Number(tools.getSession(constants.loginType) || 0) // 登录模式 , 0 预报名 ,  1 直高一接报名 , 2 高二续费 , 3 高三续费 , 4 高一正式报名, 5.高三复读报名(微信登录) 6.高三咨询服务(微信登录) 7.入学信息登记系统(线下补录)(微信登录) 10.高三复读报名(手机登录) 11.高三咨询服务(手机登录) 12.入学信息登记系统(线下补录)(手机登录)
        // 登录或token失效
        let params = ''
        router.push(getLoginPath());
        /*switch (loginType) {
          case 0: // 预报名
            router.push('/login')
            break
          case 1: // 高一直接报名
          case 2: // 高二续费
          case 3: // 高三续费
            router.push('/formal/login/login?type=' + loginType)
            break
          case 4: // 高一正式报名
            router.push('/formal/login/index')
            break
          case 5: // 高三复读报名(微信登录)
          case 6: // 高三咨询服务(微信登录)
          case 7: // 入学信息登记系统(线下补录)(微信登录)
            if (loginType - 5) {
              params = '?type=' + (loginType - 5)
            }
            router.push('/formal/login/grade3' + params)
            break
          case 10: // 高三复读报名(手机登录)
          case 11: // 高三咨询服务(手机登录)
          case 12: // 入学信息登记系统(线下补录)(手机登录)
            params = '&login_type=' + (loginType - 10)
            router.push('/formal/login/phone?type=1' + params)
            break
        }*/
        Toast({
          message: res.data.msg
        })
      } else if (res.data.status === 99) {
        router.push('/zb/Status/cannot?idCard=' + res.data.data)
      } else if (res.data.status === 3012) {
        router.push('/zb/Status/cannot?order=' + res.data.data.order)
      } else if (res.data.status === 3013) {
        router.push('/zb/Order/index?type=1')
      } else if (res.data.status === 3014) {
        router.push('/zb/Order/binding')
      } else if (res.data.status === 3015) {
        router.push('/zb/Order/index')
      } else if (res.data.status === 3017) {
        router.push('/zb/Order/repeat')
      } else {
        if (config.notify) {
          Toast({
            message: res.data.msg
          })
        }
      }
      return Promise.reject(res.data)
    },
    err => {
        tools.networkHandle(err, text => {
          if (err.config.notify)
            Toast({
              message: text
            })
      })
      return Promise.reject(err)
    }
  )
  return $axios
}
